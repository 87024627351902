import React from "react"
import { PageData } from "../data"
import { UserForm } from "../components/UserForm/UserForm"
import { Layout } from "../components/Layout/Layout"
import { graphql } from "gatsby"
import { ImageCollageContainer } from "../components/ImageCollageContainer/ImageCollageContainer"
import { OurRoomRenting } from "../components/OurRoomRenting/OurRoomRenting"
import { Paragraph } from "../components/Paragraph/Paragraph"
import { PageTitle } from "../components/PageTitle/PageTitle"
import { Heading } from "../components/PageTitle/Heading"

interface OurRoomProps {
  data: {
    cms: {
      page: {
        title: string
        ourRoomFields: {
          text: string
          rentHeading: string
          firstRoom: string
          bigRoomFirstPrice: string
          bigRoomSecondPrice: string
          secondRoom: string
          smallRoomFirstPrice: string
          smallRoomSecondPrice: string
        }
      }
      rooms: {
        nodes: {
          roomFields: {
            image: {
              sourceUrl: string
            }
          }
        }[]
      }
      collageImages: {
        nodes: {
          collageImageFields: {
            image: {
              sourceUrl: string
              mediaDetails: {
                width: number
                height: number
              }
            }
            radiobutton: string
          }
        }[]
      }
    }
  }
}

export const OurRoom: React.FC<OurRoomProps> = ({ data }) => {
  const { page } = data.cms

  const anotherStringArray: {
    title: string
    firstPrice: string
    secondPrice: string
  }[] = [
    {
      title: page.ourRoomFields.firstRoom,
      firstPrice: page.ourRoomFields.bigRoomFirstPrice,
      secondPrice: page.ourRoomFields.bigRoomSecondPrice,
    },
    {
      title: page.ourRoomFields.secondRoom,
      firstPrice: page.ourRoomFields.smallRoomFirstPrice,
      secondPrice: page.ourRoomFields.smallRoomSecondPrice,
    },
  ]

  return (
    <Layout withPadding>
      <div className="bg-HomepageBackground flex flex-col items-center">
        <div className="flex flex-col items-center lg:mb-0">
          <div className="flex w-10/12 flex-col items-center">
            <PageTitle>{page.title}</PageTitle>
            <Paragraph classname="flex flex-col items-center font-titilliumWeb text-center leading-8 w-full whitespace-pre-line mb-12 text-homepageMantraText">
              {page.ourRoomFields.text}
            </Paragraph>
          </div>

          <OurRoomRenting
            array={anotherStringArray}
            heading={page.ourRoomFields.rentHeading}
          />
        </div>
        <ImageCollageContainer
          imageContainer={data.cms.collageImages.nodes.filter(
            ({ collageImageFields: { radiobutton } }) =>
              radiobutton === "Unsere Räume",
          )}
          IndexOrOurRoom={false}
        />
      </div>
      <div className="flex w-10/12 flex-col items-center lg:relative lg:bottom-28 lg:mx-36">
        <Heading className="mb-6 text-center text-2xl tracking-wider text-headings text-opacity-70">
          {PageData.rooms.subHeading}
        </Heading>
        <UserForm />
      </div>
    </Layout>
  )
}
export default OurRoom

export const query = graphql`
  query MyQueryOurRoom {
    cms {
      page(id: "/unsere-raeume-2", idType: URI) {
        title
        ourRoomFields {
          text
          rentHeading
          firstRoom
          bigRoomFirstPrice
          bigRoomSecondPrice
          secondRoom
          smallRoomFirstPrice
          smallRoomSecondPrice
        }
      }

      collageImages(where: { orderby: { field: DATE, order: ASC } }) {
        nodes {
          collageImageFields {
            image {
              sourceUrl
              mediaDetails {
                width
                height
              }
            }
            radiobutton
          }
        }
      }
    }
  }
`
