import React from "react"
import { Paragraph } from "../Paragraph/Paragraph"
import { Heading } from "../PageTitle/Heading"

interface OurRoomRentingProps {
  array: { title: string; firstPrice: string; secondPrice: string }[]
  heading: string
}

export const OurRoomRenting: React.FC<OurRoomRentingProps> = ({
  array,
  heading,
}) => {
  return (
    <div className="bg-HomepageBackground mb-24 flex flex-col items-center md:mb-0">
      <div className="mb-20 flex w-11/12 flex-col items-center font-titilliumWeb">
        <Paragraph classname="flex flex-col items-center text-center font-titilliumWeb w-11/12 mb-12">
          {heading}
        </Paragraph>
      </div>
      <div className="flex flex-col items-center lg:mb-20">
        {array.map((content, index) => (
          <div key={index} className="flex flex-col items-center">
            <Heading className="mt-3 text-2xl tracking-widest">
              {content.title}
            </Heading>
            <div className="flex flex-row">
              <Paragraph classname="p-2 font-titilliumWeb">
                {content.firstPrice}
              </Paragraph>
              <Paragraph classname="p-2 font-titilliumWeb">
                {content.secondPrice}
              </Paragraph>
            </div>
          </div>
        ))}
      </div>
      {/*</div>*/}
    </div>
  )
}
